@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("./fonts/Sailec-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #2979FF;
  cursor: pointer;
  text-decoration: underline;
}

.MuiDataGrid-root {
  border: none!important;
}

div.success-snack {
  background-color: #029094!important;
}

@keyframes backgroundColorPalette {
  0% {
    background: #fd201a;
  }
  50% {
    background: #740000;
  }
  100% {
    background: #fd201a;
  }

}

.urgent-calls {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.deactivated-data-grid-row {
  background-color: #ffffcc;
}

.warning-data-grid-row {
  background-color: #ffa5a0;
}

ul.MuiAutocomplete-listbox {
  overflow-x: hidden;
}
